import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/images/adultComics/sort-up.png';
import _imports_1 from '@/assets/images/adultComics/sort-down.png';

const _withScopeId = n => (_pushScopeId("data-v-020330e2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "comicsContent"
};
const _hoisted_2 = {
  class: "CartoonDetail-DirectoryPage-label"
};
const _hoisted_3 = {
  class: "CartoonDetail-DirectoryPage-label-left"
};
const _hoisted_4 = {
  class: "CartoonDetail-DirectoryPage-label-right"
};
const _hoisted_5 = {
  class: "CartoonDetail-DirectoryPage-list"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  key: 0,
  class: "CartoonDetail-DirectoryPage-tips-vip"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("span", null, _toDisplayString($setup.props.comics_status === 1 ? "连载中" : "已完结"), 1), _createElementVNode("span", null, "(" + _toDisplayString("周" + "日一二三四五六".charAt(new Date().getDay())) + ")", 1), _createElementVNode("span", null, "更新至" + _toDisplayString($setup.directoryList.length) + "话", 1)]), _createElementVNode("div", _hoisted_4, [$setup.isSort ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    onClick: _cache[0] || (_cache[0] = (...args) => $setup.sortFun && $setup.sortFun(...args)),
    src: _imports_0
  })) : (_openBlock(), _createElementBlock("img", {
    key: 1,
    onClick: _cache[1] || (_cache[1] = (...args) => $setup.sortFun && $setup.sortFun(...args)),
    src: _imports_1,
    alt: ""
  }))])]), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.directoryList, item => {
    return _openBlock(), _createElementBlock("div", {
      class: "CartoonDetail-DirectoryPage-list-item",
      onClick: $event => $setup.startReading(item),
      key: item
    }, [_createElementVNode("span", null, _toDisplayString(item.title), 1), item.is_free === 0 && item.money === 0 ? (_openBlock(), _createElementBlock("span", _hoisted_7, "VIP")) : _createCommentVNode("", true)], 8, _hoisted_6);
  }), 128))])]);
}