import { getGlobalProperties, advertiseDetails } from "@/assets/js/utils.js";
import { ref } from "vue";
import { useStore } from "vuex";
export default {
  setup(props) {
    const {
      advertiseApi
    } = getGlobalProperties().$api;
    let bannerList = ref([]);
    const store = useStore();

    const getBanner = () => {
      advertiseApi({
        type: 1
      }, "get").then(res => {
        if (res.code === 0) {
          bannerList.value = res.data.rows;
        }
      });
    };

    getBanner();

    const toDetails = item => {
      if (item.link) {
        // window.open(item.link);
        store.commit("setShowDialogIsAdvertis", {
          show: true,
          url: item.link
        });
        advertiseDetails(item.link);
      }
    };

    return {
      props,
      bannerList,
      toDetails
    };
  }

};