import { getGlobalProperties } from "@/assets/js/utils.js";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Toast } from "vant";
export default {
  setup(props) {
    const store = useStore();
    const {
      newApi,
      focusSaveApi
    } = getGlobalProperties().$api;
    let page = ref(1);
    const finished = ref(false);
    let videoList = ref([]);
    const error = ref(false);
    const loading = ref(false);
    const refreshing = ref(false);
    videoList.value = localStorage.getItem("LatestComponent") ? JSON.parse(localStorage.getItem("LatestComponent")) : [];
    let isFocus = computed(() => {
      return store.state.userInfo.focus_user ? store.state.userInfo.focus_user.split(",") : [];
    });

    const getVideoList = () => {
      newApi({
        page: page.value
      }, "get").then(res => {
        refreshing.value = false; // videoList.value = [];
        // res.data.forEach((item) => {
        //   item.isFocus = isFocus.value.includes(item.author_id + "");
        // });

        if (res.code === 0) {
          if (res.data.length) {
            localStorage.setItem("LatestComponent", JSON.stringify(res.data.rows.slice(0, 3)));
          }

          videoList.value = videoList.value.concat(res.data.rows);
          loading.value = false;

          if (res.data.length === 0) {
            finished.value = true;
          }
        }
      });
    };

    getVideoList();

    const onLoad = () => {
      loading.value = true;
      page.value++;
      getVideoList();
    };

    const focusSave = item => {
      // if (userInfo.value.focus_user.indexOf(item.author_id) !== -1) {
      //   return;
      // }
      const params = {
        user_id: item.author_id
      };
      focusSaveApi(params, "get").then(res => {
        // Toast(res.message);
        if (res.code === 0) {
          item.isFocus = !item.isFocus;
          store.dispatch("getUserInfo");
          getVideoList();
        }
      });
    };

    const videoPlay = item => {
      store.commit("SET_VIDEO_DETAILS", item);
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "VideoDetails"
      });
    };

    let userInfo = computed(() => {
      return store.state.userInfo;
    });

    const toAutorDetails = item => {
      item.user_id = item.author_id;
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "HotAuthorInfo"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    const onRefresh = () => {
      videoList.value = [];
      loading.value = true;
      page.value = 1;
      getVideoList();
    };

    return {
      props,
      videoList,
      videoPlay,
      focusSave,
      userInfo,
      loading,
      onLoad,
      error,
      finished,
      toAutorDetails,
      onRefresh,
      refreshing
    };
  }

};