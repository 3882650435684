import { computed, ref } from "vue";
import FocusComponent from "./tabs/focus/FocusComponent.vue";
import RecommondComponent from "./tabs/recommond/RecommondComponent.vue";
import LatestComponent from "./tabs/latest/LatestComponent.vue";
import StudioVideo from "./tabs/studio/StudioVideo.vue";
import todaysHeadlines from "./tabs/todays/todaysHeadlines.vue";
import AdultComics from "./tabs/adultComics/AdultComics.vue";
import { useStore } from "vuex";
import { Dialog } from "vant";
export default {
  components: {
    FocusComponent,
    RecommondComponent,
    LatestComponent,
    StudioVideo,
    todaysHeadlines,
    AdultComics
  },
  name: "HomeView",

  setup(props, {
    emit
  }) {
    const store = useStore(); // store.dispatch("getUserInfo");

    const active = ref(1);
    const showAdultComicsPopul = ref(false);
    const skeletonLoading = ref(true);
    const showBottomBanner = computed(() => {
      return store.state.showBottomBanner;
    });
    let navs = ref([{
      name: "关注",
      id: "gz",
      component: "FocusComponent"
    }, {
      name: "推荐",
      id: "tj",
      component: "RecommondComponent"
    }, {
      name: "制片厂",
      id: "zpc",
      component: "StudioVideo"
    }, {
      name: "今日头条",
      id: "jrtt",
      component: "todaysHeadlines"
    }, {
      name: "最新",
      id: "zx",
      component: "LatestComponent"
    }]);
    let currentComponent = computed(() => {
      return navs.value[active.value]["component"];
    });

    const skeletonF = () => {
      skeletonLoading.value = false;
    }; // const { homeMenuApi, labelApi, recommendApi, fansApi, emphasisApi, movieMakerApi } = getGlobalProperties().$api;


    const handleShowStack = path => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: path
      });
    }; // const getMenu = () => {
    //   fansApi(" ", "get").then((res) => {
    //     console.log(res);
    //     if (res.code === 0) {
    //     }
    //   });
    // };
    // getMenu()


    const toFaxian = () => {
      emit("toFaxian");
    };

    const releaseValue = () => {
      Dialog.alert({
        title: "温馨提示",
        showCancelButton: true,
        confirmButtonText: "下载APP",
        cancelButtonText: "确认",
        className: "okAlertPopup",
        message: "请下载采花阁APP使用上传功能！"
      }).then(() => {
        window.open(store.state.baseUrl.h5_download_url);
      });
    };

    const toManhua = () => {
      showAdultComicsPopul.value = true;
    };

    const close = () => {
      showAdultComicsPopul.value = false;
    };

    return {
      props,
      navs,
      active,
      currentComponent,
      handleShowStack,
      toFaxian,
      releaseValue,
      toManhua,
      showAdultComicsPopul,
      close,
      showBottomBanner,
      skeletonLoading,
      skeletonF
    };
  }

};