import Featured from "./components/FeaturedComponent.vue";
import Classification from "./components/ClassificationComponent.vue";
import { useStore } from "vuex";
import { ref, nextTick, computed } from "vue";
export default {
  name: "NoData",
  components: {
    Featured,
    Classification
  },
  props: {
    fxActive: {
      type: Number,
      default: 0
    }
  },

  setup(props) {
    let active = ref(0);
    let shoeLoading = ref(true);
    active = ref(props.fxActive);
    const store = useStore();

    const handleShowStack = path => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: path
      });
    };

    const showBottomBanner = computed(() => {
      return store.state.showBottomBanner;
    });
    nextTick(() => {
      shoeLoading.value = false;
    });
    return {
      props,
      active,
      handleShowStack,
      shoeLoading,
      showBottomBanner
    };
  }

};