import { getGlobalProperties } from "@/assets/js/utils.js";
import SwipeComponent from "../common/SwipeComponent.vue";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Toast } from "vant";
export default {
  components: {
    SwipeComponent
  },

  setup(props) {
    const store = useStore();
    const {
      emphasisApi,
      focusSaveApi
    } = getGlobalProperties().$api;
    let page = ref(0);
    const finished = ref(false);
    let videoList = ref([{}, {}]);
    const error = ref(false);
    const loading = ref(false);
    const refreshing = ref(false);
    videoList.value = localStorage.getItem("todaysHeadlines") ? JSON.parse(localStorage.getItem("todaysHeadlines")) : [];
    let isFocus = computed(() => {
      return store.state.userInfo.focus_user.split(",");
    });

    const getVideoList = () => {
      emphasisApi({
        page: page.value
      }, "get").then(res => {
        if (page.value === 1) {
          videoList.value = [];
        }

        refreshing.value = false;

        if (res.code === 0) {
          res.data.rows.forEach(item => {
            item.isFocus = isFocus.value.includes(item.user_id + "");
          });

          if (page.value === 1) {
            localStorage.setItem("todaysHeadlines", JSON.stringify(res.data.rows.slice(0, 3)));
          }

          videoList.value = videoList.value.concat(res.data.rows);
          loading.value = false;

          if (res.data.rows.length === 0) {
            finished.value = true;
          }
        }
      });
    };

    const onLoad = () => {
      loading.value = true;
      page.value++;
      getVideoList();
    };

    if (page.value === 0 && videoList.value.length !== 0) {
      page.value++;
      getVideoList();
    }

    const focusSave = item => {
      // if (item.isFocus) {
      //   return;
      // }
      const params = {
        user_id: item.user_id
      };
      focusSaveApi(params, "get").then(res => {
        // Toast(res.message);
        if (res.code === 0) {
          item.isFocus = !item.isFocus;
          store.dispatch("getUserInfo");
          page.value = 1;
          getVideoList();
        }
      });
    };

    const videoPlay = item => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "VideoDetails"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    const toAutorDetails = item => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "HotAuthorInfo"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    const showShare = item => {
      store.commit("SET_SHOW_SHARE_POPUP", {
        show: true,
        videoDetails: item
      });
    }; // const getBanner = () => {
    //   bannerApi("", "get").then((res) => {});
    // };
    // getBanner();


    const onRefresh = () => {
      videoList.value = [];
      loading.value = true;
      page.value = 1;
      getVideoList();
    };

    return {
      props,
      videoList,
      loading,
      onLoad,
      error,
      videoPlay,
      finished,
      focusSave,
      toAutorDetails,
      showShare,
      onRefresh,
      refreshing
    };
  }

};