import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { getGlobalProperties, getMyDate } from "@/assets/js/utils.js";
export default {
  name: "SearchPopup",
  props: {
    searchText: {
      type: String,
      default: ""
    },
    label_id: {
      type: Number,
      default: ""
    },
    menu_id: {
      type: Number,
      default: ""
    },
    label_name: {
      type: String,
      default: ""
    },
    type: {
      type: String
    }
  },

  setup(props, {
    emit
  }) {
    const {
      searchApi,
      labelTypeApi,
      menuTypeApi,
      labelSearchApi
    } = getGlobalProperties().$api;
    const store = useStore();
    let page = ref(1);
    const finished = ref(false);
    const error = ref(false);
    const loading = ref(false);
    let showDetailsPopul = ref(false);
    let videoId = ref("");
    const showLoading = ref(true);
    const searchText = computed(() => {
      // 计算属性初始化加10
      return ref(props.searchText);
    });
    const label_id = computed(() => {
      // 计算属性初始化加10
      return ref(props.label_id);
    });
    const menu_id = computed(() => {
      // 计算属性初始化加10
      return ref(props.menu_id);
    });
    const label_name = computed(() => {
      // 计算属性初始化加10
      return ref(props.label_name);
    }); // const searchText = ref(props.searchText);

    const detailsTitle = ref("");
    const tjList = ref([]);

    const search = () => {
      console.log(searchText);

      if (page.value === 1) {
        showLoading.value = true;
        finished.value = false;
      }

      detailsTitle.value = "#" + searchText.value.value;

      if (!label_id.value.value) {
        if (menu_id.value.value) {
          detailsTitle.value = searchText.value.value;
          menuTypeApi({
            menu_id: menu_id.value.value,
            page: page.value
          }, "get").then(res => {
            showLoading.value = false;

            if (res.code === 0) {
              tjList.value = tjList.value.concat(res.data.rows);
              loading.value = false;

              if (res.data.rows.length === 0) {
                finished.value = true;
              }
            }
          });
        } else if (label_name.value.value) {
          labelSearchApi({
            label_name: label_name.value.value,
            page: page.value
          }, "get").then(res => {
            showLoading.value = false;

            if (res.code === 0) {
              tjList.value = tjList.value.concat(res.data.rows);
              loading.value = false;

              if (res.data.rows.length === 0) {
                finished.value = true;
              }
            }
          });
        } else {
          searchApi({
            data: searchText.value.value,
            page: page.value
          }, "get").then(res => {
            showLoading.value = false;

            if (res.code === 0) {
              tjList.value = res.data;
              loading.value = false;
              finished.value = true;
            }
          });
        }
      } else {
        labelTypeApi({
          label_id: label_id.value.value,
          menu_id: menu_id.value.value,
          page: page.value
        }, "get").then(res => {
          showLoading.value = false;

          if (res.code === 0) {
            tjList.value = tjList.value.concat(res.data.rows);
            loading.value = false;

            if (res.data.rows.length === 0) {
              finished.value = true;
            }
          }
        });
      }
    };

    search();

    const onLoad = () => {
      loading.value = true;
      page.value++;
      search();
    };

    const videoPlay = item => {
      store.commit("SET_VIDEO_DETAILS", item);
      console.log(props.type);

      if (props.type === "details") {
        emit("close");
      } else {
        showDetailsPopul.value = true;
        videoId.value = Math.random();
      }
    };

    watch(searchText, () => {
      tjList.value = [];
      page.value = 1;
      search();
    });

    const close = () => {
      emit("close");
    };

    const close1 = () => {
      showDetailsPopul.value = false;
    };

    return {
      props,
      getMyDate,
      detailsTitle,
      tjList,
      videoPlay,
      close,
      loading,
      onLoad,
      error,
      finished,
      close1,
      showDetailsPopul,
      videoId,
      showLoading
    };
  }

};