import { ref } from "vue";
import { useStore } from "vuex";
import { Dialog } from "vant";
import SwipeComponent from "../home/tabs/common/SwipeComponent.vue";
import AskForVideo from "./components/AskForVideo.vue";
import MicroHeadlines from "./components/MicroHeadlines.vue";
export default {
  name: "CommunityView",
  components: {
    SwipeComponent,
    AskForVideo,
    MicroHeadlines
  },

  setup(props) {
    let active = ref(0);
    const store = useStore();

    const handleShowStack = path => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: path
      });
    };

    const releaseValue = () => {
      Dialog.alert({
        title: "温馨提示",
        showCancelButton: true,
        confirmButtonText: "下载APP",
        cancelButtonText: "确认",
        className: "okAlertPopup",
        message: "请下载采花阁APP使用上传功能！"
      }).then(() => {
        window.open(store.state.baseUrl.h5_download_url);
      });
    };

    return {
      props,
      active,
      handleShowStack,
      releaseValue
    };
  }

};