import { advertiseDetails } from "@/assets/js/utils.js";
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  setup(props) {
    const store = useStore();
    let bannerList = computed(() => {
      // 计算属性初始化加10
      return store.state.bannerList;
    });

    const toDetails = item => {
      if (item.link) {
        if (item.link === "vip") {
          store.commit("SET_LOGIN_POPUP", {
            show: true,
            type: "Recharge"
          });
        } else if (item.link === "invitation") {
          store.commit("SET_LOGIN_POPUP", {
            show: true,
            type: "ShareFreeWatch"
          });
        } else {
          store.commit("setShowDialogIsAdvertis", {
            show: true,
            url: item.link
          });
          advertiseDetails(item.link);
        }
      }
    };

    return {
      props,
      bannerList,
      toDetails
    };
  }

};