import { ref } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import SwipeComponent from "../../home/tabs/common/SwipeComponent.vue";
import { useStore } from "vuex";
export default {
  components: {
    SwipeComponent
  },

  setup(props) {
    let page = ref(1);
    let showDetailsPopul = ref(false);
    let menu_id = ref("");
    let menuTetx = ref("");
    const finished = ref(false);
    let videoList = ref([]);
    const error = ref(false);
    const loading = ref(false);
    const store = useStore();
    let showLoding = ref(true);
    const {
      concentrationApi
    } = getGlobalProperties().$api;
    videoList.value = localStorage.getItem("FeaturedComponent") ? JSON.parse(localStorage.getItem("FeaturedComponent")) : [];

    const getVideoList = () => {
      concentrationApi({}, "get").then(res => {
        showLoding.value = false;
        videoList.value = [];

        if (res.code === 0) {
          if (res.data.length) {
            localStorage.setItem("FeaturedComponent", JSON.stringify([res.data[0]]));
          }

          videoList.value = res.data;
          loading.value = false; // if (res.data.length === 0) {

          finished.value = true; // }
        }
      });
    };

    getVideoList();

    const onLoad = () => {// loading.value = true;
      // page.value++;
      // getVideoList();
    };

    const menuAll = item => {
      menu_id.value = item.category_id;
      menuTetx.value = item.name;
      showDetailsPopul.value = true;
    };

    const videoPlay = item => {
      store.commit("SET_LOGIN_POPUP", {
        show: true,
        type: "VideoDetails"
      });
      store.commit("SET_VIDEO_DETAILS", item);
    };

    const close = () => {
      showDetailsPopul.value = false;
      menuTetx.value = "";
    };

    return {
      props,
      videoList,
      loading,
      onLoad,
      error,
      videoPlay,
      finished,
      showLoding,
      showDetailsPopul,
      menu_id,
      menuAll,
      menuTetx,
      close
    };
  }

};