import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-4d2c86de"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "head_title"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  key: 1,
  class: "cont_body"
};
const _hoisted_4 = {
  key: 0,
  class: "info-recommend"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  class: "info-videoCover"
};
const _hoisted_7 = {
  class: "info-videoCover-img"
};
const _hoisted_8 = {
  class: "info-videoInfo"
};
const _hoisted_9 = {
  key: 1,
  class: "no-data"
};
const _hoisted_10 = {
  class: "details_page"
};
const _hoisted_11 = {
  class: "cont_body"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");

  const _component_Loading = _resolveComponent("Loading");

  const _component_my_image = _resolveComponent("my-image");

  const _component_van_list = _resolveComponent("van-list");

  const _component_no_data = _resolveComponent("no-data");

  const _component_video_details = _resolveComponent("video-details");

  const _component_van_popup = _resolveComponent("van-popup");

  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_icon, {
    size: "22",
    name: "arrow-left",
    onClick: $setup.close
  }, null, 8, ["onClick"]), _createElementVNode("span", _hoisted_2, _toDisplayString($setup.detailsTitle), 1)]), $setup.showLoading ? (_openBlock(), _createBlock(_component_Loading, {
    key: 0
  })) : (_openBlock(), _createElementBlock("div", _hoisted_3, [$setup.tjList.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_van_list, {
    loading: $setup.loading,
    "onUpdate:loading": _cache[0] || (_cache[0] = $event => $setup.loading = $event),
    error: $setup.error,
    "onUpdate:error": _cache[1] || (_cache[1] = $event => $setup.error = $event),
    finished: $setup.finished,
    "error-text": "请求失败，点击重新加载",
    "finished-text": "-我也是有底线的-",
    "loading-text": "正在获取数据...",
    onLoad: $setup.onLoad
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tjList, (item, index) => {
      return _openBlock(), _createElementBlock("div", {
        key: index,
        class: "info-videoItem",
        onClick: $event => $setup.videoPlay(item)
      }, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("div", null, [_createVNode(_component_my_image, {
        url: item.image
      }, null, 8, ["url"])])]), _createElementVNode("span", null, _toDisplayString($setup.getMyDate(item.created, 2)), 1)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("p", null, _toDisplayString(item.title), 1), _createElementVNode("div", null, [_createElementVNode("span", null, _toDisplayString(item.user_label || ""), 1), _createElementVNode("span", null, _toDisplayString(item.count) + "次播放", 1)])])], 8, _hoisted_5);
    }), 128))]),
    _: 1
  }, 8, ["loading", "error", "finished", "onLoad"])])) : (_openBlock(), _createElementBlock("div", _hoisted_9, [_createVNode(_component_no_data, {
    color: "#ffffff"
  })]))])), _createVNode(_component_van_popup, {
    show: $setup.showDetailsPopul,
    "onUpdate:show": _cache[2] || (_cache[2] = $event => $setup.showDetailsPopul = $event),
    class: "popup_coentent",
    overlay: false,
    position: "right"
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [(_openBlock(), _createBlock(_component_video_details, {
      key: $setup.videoId,
      onClose: $setup.close1
    }, null, 8, ["onClose"]))])])]),
    _: 1
  }, 8, ["show"])]);
}