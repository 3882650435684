import { useStore } from "vuex";
import { ref } from "vue";
import { getGlobalProperties } from "@/assets/js/utils.js";
import { Toast } from "vant";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",

  setup(props) {
    const store = useStore();

    const close = () => {
      store.commit("SET_LOGIN_POPUP", {
        show: false,
        type: "Login"
      });
    };

    const {
      loginApi,
      modifyUserApi
    } = getGlobalProperties().$api;
    let params = ref({
      username: "",
      password: ""
    });

    const register = () => {
      if (!params.value.username) {
        Toast("请输入账号/手机号");
        return;
      }

      if (!params.value.password) {
        Toast("请输入密码");
        return;
      }

      if (!store.state.userInfo.username) {
        params.value.nickname = store.state.userInfo.nickname;
        modifyUserApi(params.value).then(res => {
          console.log(res);

          if (res.code === 0) {
            window.location = "/";
          } else {
            Toast(res.message);
          }
        });
      } else {
        Toast("您已经注册过了"); // params.value.nickname = getRandomName();
        // registerAPI(params.value).then((res) => {
        //   console.log(res);
        //   if (res.code === 0) {
        //     res.data.nickname = params.value.nickname;
        //     store.commit("SET_USER_INFO", res.data);
        //     window.location = "/";
        //   } else {
        //     Toast(res.message);
        //   }
        // });
      }
    };

    const login = () => {
      if (!params.value.username) {
        Toast("请输入账号/手机号");
        return;
      }

      if (!params.value.password) {
        Toast("请输入密码");
        return;
      }

      loginApi(params.value).then(res => {
        console.log(res);

        if (res.code === 0) {
          store.commit("SET_USER_INFO", res.data);
          store.commit("SET_HG_OAUTH_ID", "login");
          window.location = "/";
        } else {
          Toast(res.message);
        }
      });
    };

    return {
      props,
      close,
      register,
      params,
      login
    };
  }

};